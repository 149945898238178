import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { CheckIcon } from '@heroicons/react/outline'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

function Page({ location }) {
  const { site, shareImage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneDisplay
        }
      }
      shareImage: file(
        relativePath: { eq: "services/patio-decking-banner-1.webp" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const { phone, phoneDisplay } = site.siteMetadata

  return (
    <>
      <Meta
        title="Composite Decking"
        description="We are professional installers of composite decking, catering for both domestic and commercial customers."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="lg:flex justify-between bg-gray-lightest border-b-8 border-green">
          <div className="flex-1 max-w-7xl">
            <StaticImage
              src="../images/services/patio-decking-banner-1.webp"
              alt="Featured decking work"
              className="aspect-w-16 aspect-h-7"
              aspectRatio={16 / 7}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </div>
          <div className="grid justify-center content-center mx-auto py-12 px-8 lg:py-16 lg:px-12">
            <h2 className="uppercase text-4xl font-extrabold sm:text-5xl">
              Composite decking
            </h2>
            <p className="max-w-3xl mt-3 text-xl sm:mt-4 lg:text-2xl">
              We are professional installers of composite decking, catering for
              both domestic and commercial customers.
            </p>
          </div>
        </section>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                What is Composite Decking?
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    Composite decking is a relatively new material that is
                    designed for us to build beautiful decked areas, but withiut
                    the compromises that come with traditional timber decking.
                    Composite decking is a synthetic material, made to be long
                    lasting with it&apos;s anti-rot, anti-slip and low
                    maintenance properties. Not only does it have great
                    practical benefits, but it also has aesthetic benefits.
                    Composite decking can come in a myriad of colours and
                    textures, and it will look great all year round.
                  </p>
                </div>
              </div>
            </div>
            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Anti-Rot
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Composite is anti-rot, anti-split, and anti-warp when compared
                  to timber decking.
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Anti-Slip
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Composite has low water absorbtion, meaning slippery lichen
                  doesn&apos;t grow as it does with timber decking.
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Low Maintenance
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Just a wash down with a brush and water is required.
                </dd>
              </div>
            </dl>
          </div>
        </section>
        <section className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-lightest" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-3 bg-white text-2xl font-medium text-gray">
              Products
            </span>
          </div>
        </section>
        <section className="bg-white py-16 sm:py-24 space-y-12">
          <div className="max-w-7xl mx-auto px-4 divide-y-2 divide-gray sm:px-6 lg:px-8">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
              <div className="relative">
                <dt className="text-lg leading-6 font-medium text-gray">
                  Where we cover
                </dt>
                <dd className="mt-2 text-base text-gray-light">
                  We cover most areas in Berkshire and Surrey, and extend our
                  services to Hampshire and London for commercial clients.
                </dd>
              </div>
              <div className="relative">
                <dt className="text-lg leading-6 font-medium text-gray">
                  Products we install
                </dt>
                <dd className="mt-2 text-base text-gray-light">
                  We are capable of installing any composite decking product
                  that is specified by our clients. But for our clients who
                  don&apos;t have a specified product, we can recommend two
                  different products for two different budgets.
                </dd>
              </div>
            </dl>
          </div>
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-gray-light text-green-light">
                      Classic
                    </h3>
                  </div>
                  <StaticImage
                    className="mt-4 rounded-2xl"
                    src="../images/services/classic-composite.webp"
                    alt="Classic composite decking"
                  />
                  <p className="mt-5 text-lg text-gray-light uppercase">
                    Product: Neotimber
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">Anti-Slip</p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">Anti-Rot</p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        Low Maintenance
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        3 Colours (grey, teak, chocolate)
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <p className="text-4xl font-extrabold">
                    From &pound;145
                    <br />
                    <small className="text-2xl font-medium text-gray-light">
                      per square meter, inc VAT
                    </small>
                  </p>
                </div>
              </div>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-gray-light text-green-light">
                      Premium
                    </h3>
                  </div>
                  <StaticImage
                    className="mt-4 rounded-2xl"
                    src="../images/services/premium-composite.webp"
                    alt="Premium composite decking"
                  />
                  <p className="mt-5 text-lg text-gray-light uppercase">
                    Product: Millboard
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">Anti-Slip</p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">Anti-Rot</p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        Low Maintenance
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        Myriad of colours / textures
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        Moulded from real wood
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-6 w-6 text-green-light"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-base text-gray">
                        Superior quality
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <p className="text-4xl font-extrabold">
                    From &pound;190
                    <br />
                    <small className="text-2xl font-medium text-gray-light">
                      per square meter, inc VAT
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="rounded-lg bg-gray-lightest px-6 py-8 sm:p-10">
                <p className="text-xl text-gray">
                  Samples provided. Commercial rates available POA.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="text-white bg-gray-light">
          <p className="max-w-4xl mx-auto text-center px-4 py-12 sm:px-6 lg:px-8">
            <strong className="text-3xl font-extrabold sm:text-4xl">
              Call us on{' '}
              <a className="text-green-light" href={`tel:${phone}`}>
                {phoneDisplay}
              </a>
            </strong>
            <br />
            <span className="mt-3 text-xl sm:mt-4">to arrange a quotation</span>
          </p>
        </section>
        <section className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray text-center">
              Case Study
            </h3>
            <p className="mt-5 max-w-prose mx-auto text-center font-semibold text-gray-light tracking-wider">
              We installed this beautiful decking at the presigious Boulters
              Lock Restaurant in Bray, Maidenhead. We used the Millboard
              enhanced grain golden oak, and the results were superb.
            </p>
            <div className="mt-6 grid gap-1 lg:grid-cols-5 lg:mt-8">
              <div className="col-span-2 text-center">
                <StaticImage
                  src="../images/services/composite-decking-feature-1.webp"
                  alt="Featured work"
                  placeholder="blurred"
                />
              </div>
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/services/composite-decking-feature-2.webp"
                  alt="Featured work"
                  placeholder="blurred"
                />
              </div>
              <div className="col-span-2 text-center">
                <StaticImage
                  src="../images/services/composite-decking-feature-3.webp"
                  alt="Featured work"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
